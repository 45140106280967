export const countries = [
    {
      "value": "AF",
      "label": "Afghanistan",
      "details": {
        "code": "AF",
        "name": "Afghanistan",
        "region": "asia"
      },
      "key": "AF"
    },
    {
      "value": "AL",
      "label": "Albania",
      "details": {
        "code": "AL",
        "name": "Albania",
        "region": "europe"
      },
      "key": "AL"
    },
    {
      "value": "DZ",
      "label": "Algeria",
      "details": {
        "code": "DZ",
        "name": "Algeria",
        "region": "africa"
      },
      "key": "DZ"
    },
    {
      "value": "AS",
      "label": "American Samoa",
      "details": {
        "code": "AS",
        "name": "American Samoa",
        "region": "oceania"
      },
      "key": "AS"
    },
    {
      "value": "AD",
      "label": "Andorra",
      "details": {
        "code": "AD",
        "name": "Andorra",
        "region": "europe"
      },
      "key": "AD"
    },
    {
      "value": "AO",
      "label": "Angola",
      "details": {
        "code": "AO",
        "name": "Angola",
        "region": "africa"
      },
      "key": "AO"
    },
    {
      "value": "AI",
      "label": "Anguilla",
      "details": {
        "code": "AI",
        "name": "Anguilla",
        "region": "americas"
      },
      "key": "AI"
    },
    {
      "value": "AQ",
      "label": "Antarctica",
      "details": {
        "code": "AQ",
        "name": "Antarctica",
        "region": ""
      },
      "key": "AQ"
    },
    {
      "value": "AG",
      "label": "Antigua and Barbuda",
      "details": {
        "code": "AG",
        "name": "Antigua and Barbuda",
        "region": "americas"
      },
      "key": "AG"
    },
    {
      "value": "AR",
      "label": "Argentina",
      "details": {
        "code": "AR",
        "name": "Argentina",
        "region": "americas"
      },
      "key": "AR"
    },
    {
      "value": "AM",
      "label": "Armenia",
      "details": {
        "code": "AM",
        "name": "Armenia",
        "region": "asia"
      },
      "key": "AM"
    },
    {
      "value": "AW",
      "label": "Aruba",
      "details": {
        "code": "AW",
        "name": "Aruba",
        "region": "americas"
      },
      "key": "AW"
    },
    {
      "value": "AU",
      "label": "Australia",
      "details": {
        "code": "AU",
        "name": "Australia",
        "region": "oceania"
      },
      "key": "AU"
    },
    {
      "value": "AT",
      "label": "Austria",
      "details": {
        "code": "AT",
        "name": "Austria",
        "region": "europe"
      },
      "key": "AT"
    },
    {
      "value": "AZ",
      "label": "Azerbaijan",
      "details": {
        "code": "AZ",
        "name": "Azerbaijan",
        "region": "asia"
      },
      "key": "AZ"
    },
    {
      "value": "BS",
      "label": "Bahamas",
      "details": {
        "code": "BS",
        "name": "Bahamas",
        "region": "americas"
      },
      "key": "BS"
    },
    {
      "value": "BH",
      "label": "Bahrain",
      "details": {
        "code": "BH",
        "name": "Bahrain",
        "region": "asia"
      },
      "key": "BH"
    },
    {
      "value": "BD",
      "label": "Bangladesh",
      "details": {
        "code": "BD",
        "name": "Bangladesh",
        "region": "asia"
      },
      "key": "BD"
    },
    {
      "value": "BB",
      "label": "Barbados",
      "details": {
        "code": "BB",
        "name": "Barbados",
        "region": "americas"
      },
      "key": "BB"
    },
    {
      "value": "BY",
      "label": "Belarus",
      "details": {
        "code": "BY",
        "name": "Belarus",
        "region": "europe"
      },
      "key": "BY"
    },
    {
      "value": "BE",
      "label": "Belgium",
      "details": {
        "code": "BE",
        "name": "Belgium",
        "Region": "Europe",
        "Capital": "Brussels",
        "region": "europe"
      },
      "key": "BE"
    },
    {
      "value": "BZ",
      "label": "Belize",
      "details": {
        "code": "BZ",
        "name": "Belize",
        "region": "americas"
      },
      "key": "BZ"
    },
    {
      "value": "BJ",
      "label": "Benin",
      "details": {
        "code": "BJ",
        "name": "Benin",
        "region": "africa"
      },
      "key": "BJ"
    },
    {
      "value": "BM",
      "label": "Bermuda",
      "details": {
        "code": "BM",
        "name": "Bermuda",
        "region": "americas"
      },
      "key": "BM"
    },
    {
      "value": "BT",
      "label": "Bhutan",
      "details": {
        "code": "BT",
        "name": "Bhutan",
        "region": "asia"
      },
      "key": "BT"
    },
    {
      "value": "BO",
      "label": "Bolivia",
      "details": {
        "code": "BO",
        "name": "Bolivia",
        "region": "americas"
      },
      "key": "BO"
    },
    {
      "value": "BQ",
      "label": "Bonaire, Sint Eustatius, and Saba",
      "details": {
        "code": "BQ",
        "name": "Bonaire, Sint Eustatius, and Saba",
        "region": "americas"
      },
      "key": "BQ"
    },
    {
      "value": "BA",
      "label": "Bosnia and Herzegovina",
      "details": {
        "code": "BA",
        "name": "Bosnia and Herzegovina",
        "region": "europe"
      },
      "key": "BA"
    },
    {
      "value": "BW",
      "label": "Botswana",
      "details": {
        "code": "BW",
        "name": "Botswana",
        "region": "africa"
      },
      "key": "BW"
    },
    {
      "value": "BV",
      "label": "Bouvet Island",
      "details": {
        "code": "BV",
        "name": "Bouvet Island",
        "region": ""
      },
      "key": "BV"
    },
    {
      "value": "BR",
      "label": "Brazil",
      "details": {
        "code": "BR",
        "name": "Brazil",
        "region": "americas"
      },
      "key": "BR"
    },
    {
      "value": "IO",
      "label": "British Indian Ocean Territory",
      "details": {
        "code": "IO",
        "name": "British Indian Ocean Territory",
        "region": "africa"
      },
      "key": "IO"
    },
    {
      "value": "VG",
      "label": "British Virgin Islands",
      "details": {
        "code": "VG",
        "name": "British Virgin Islands",
        "region": "americas"
      },
      "key": "VG"
    },
    {
      "value": "BN",
      "label": "Brunei",
      "details": {
        "code": "BN",
        "name": "Brunei",
        "region": "asia"
      },
      "key": "BN"
    },
    {
      "value": "BG",
      "label": "Bulgaria",
      "details": {
        "code": "BG",
        "name": "Bulgaria",
        "region": "europe"
      },
      "key": "BG"
    },
    {
      "value": "BF",
      "label": "Burkina Faso",
      "details": {
        "code": "BF",
        "name": "Burkina Faso",
        "region": "africa"
      },
      "key": "BF"
    },
    {
      "value": "BI",
      "label": "Burundi",
      "details": {
        "code": "BI",
        "name": "Burundi",
        "region": "africa"
      },
      "key": "BI"
    },
    {
      "value": "CV",
      "label": "Cabo Verde",
      "details": {
        "code": "CV",
        "name": "Cabo Verde",
        "region": "africa"
      },
      "key": "CV"
    },
    {
      "value": "IC",
      "label": "Canary Islands",
      "details": {
        "code": "IC",
        "name": "Canary Islands"
      },
      "key": "IC"
    },
    {
      "value": "KH",
      "label": "Cambodia",
      "details": {
        "code": "KH",
        "name": "Cambodia",
        "region": "asia"
      },
      "key": "KH"
    },
    {
      "value": "CM",
      "label": "Cameroon",
      "details": {
        "code": "CM",
        "name": "Cameroon",
        "region": "africa"
      },
      "key": "CM"
    },
    {
      "value": "CA",
      "label": "Canada",
      "details": {
        "code": "CA",
        "name": "Canada",
        "region": "americas"
      },
      "key": "CA"
    },
    {
      "value": "KY",
      "label": "Cayman Islands",
      "details": {
        "code": "KY",
        "name": "Cayman Islands",
        "region": "americas"
      },
      "key": "KY"
    },
    {
      "value": "CF",
      "label": "Central African Republic",
      "details": {
        "code": "CF",
        "name": "Central African Republic",
        "region": "africa"
      },
      "key": "CF"
    },
    {
      "value": "TD",
      "label": "Chad",
      "details": {
        "code": "TD",
        "name": "Chad",
        "region": "africa"
      },
      "key": "TD"
    },
    {
      "value": "CL",
      "label": "Chile",
      "details": {
        "code": "CL",
        "name": "Chile",
        "region": "americas"
      },
      "key": "CL"
    },
    {
      "value": "CN",
      "label": "China",
      "details": {
        "code": "CN",
        "name": "China",
        "region": "asia"
      },
      "key": "CN"
    },
    {
      "value": "CX",
      "label": "Christmas Island",
      "details": {
        "code": "CX",
        "name": "Christmas Island",
        "region": "oceania"
      },
      "key": "CX"
    },
    {
      "value": "CC",
      "label": "Cocos [Keeling] Islands",
      "details": {
        "code": "CC",
        "name": "Cocos [Keeling] Islands",
        "region": "oceania"
      },
      "key": "CC"
    },
    {
      "value": "CO",
      "label": "Colombia",
      "details": {
        "code": "CO",
        "name": "Colombia",
        "region": "americas"
      },
      "key": "CO"
    },
    {
      "value": "KM",
      "label": "Comoros",
      "details": {
        "code": "KM",
        "name": "Comoros",
        "region": "africa"
      },
      "key": "KM"
    },
    {
      "value": "CG",
      "label": "Congo",
      "details": {
        "code": "CG",
        "name": "Congo",
        "region": "africa"
      },
      "key": "CG"
    },
    {
      "value": "CK",
      "label": "Cook Islands",
      "details": {
        "code": "CK",
        "name": "Cook Islands",
        "region": "oceania"
      },
      "key": "CK"
    },
    {
      "value": "CR",
      "label": "Costa Rica",
      "details": {
        "code": "CR",
        "name": "Costa Rica",
        "region": "americas"
      },
      "key": "CR"
    },
    {
      "value": "HR",
      "label": "Croatia",
      "details": {
        "code": "HR",
        "name": "Croatia",
        "region": "europe"
      },
      "key": "HR"
    },
    {
      "value": "CU",
      "label": "Cuba",
      "details": {
        "code": "CU",
        "name": "Cuba",
        "region": "americas"
      },
      "key": "CU"
    },
    {
      "value": "CW",
      "label": "Curaçao",
      "details": {
        "code": "CW",
        "name": "Curaçao",
        "region": "americas"
      },
      "key": "CW"
    },
    {
      "value": "CY",
      "label": "Cyprus",
      "details": {
        "code": "CY",
        "name": "Cyprus",
        "region": "asia"
      },
      "key": "CY"
    },
    {
      "value": "CZ",
      "label": "Czechia",
      "details": {
        "code": "CZ",
        "name": "Czechia",
        "region": "europe"
      },
      "key": "CZ"
    },
    {
      "value": "CD",
      "label": "DR Congo",
      "details": {
        "code": "CD",
        "name": "DR Congo",
        "region": "africa"
      },
      "key": "CD"
    },
    {
      "value": "DK",
      "label": "Denmark",
      "details": {
        "code": "DK",
        "name": "Denmark",
        "region": "europe"
      },
      "key": "DK"
    },
    {
      "value": "DJ",
      "label": "Djibouti",
      "details": {
        "code": "DJ",
        "name": "Djibouti",
        "region": "africa"
      },
      "key": "DJ"
    },
    {
      "value": "DM",
      "label": "Dominica",
      "details": {
        "code": "DM",
        "name": "Dominica",
        "region": "americas"
      },
      "key": "DM"
    },
    {
      "value": "DO",
      "label": "Dominican Republic",
      "details": {
        "code": "DO",
        "name": "Dominican Republic",
        "region": "americas"
      },
      "key": "DO"
    },
    {
      "value": "EC",
      "label": "Ecuador",
      "details": {
        "code": "EC",
        "name": "Ecuador",
        "region": "americas"
      },
      "key": "EC"
    },
    {
      "value": "EG",
      "label": "Egypt",
      "details": {
        "code": "EG",
        "name": "Egypt",
        "region": "africa"
      },
      "key": "EG"
    },
    {
      "value": "SV",
      "label": "El Salvador",
      "details": {
        "code": "SV",
        "name": "El Salvador",
        "region": "americas"
      },
      "key": "SV"
    },
    {
      "value": "GQ",
      "label": "Equatorial Guinea",
      "details": {
        "code": "GQ",
        "name": "Equatorial Guinea",
        "region": "africa"
      },
      "key": "GQ"
    },
    {
      "value": "ER",
      "label": "Eritrea",
      "details": {
        "code": "ER",
        "name": "Eritrea",
        "region": "africa"
      },
      "key": "ER"
    },
    {
      "value": "EE",
      "label": "Estonia",
      "details": {
        "code": "EE",
        "name": "Estonia",
        "region": "europe"
      },
      "key": "EE"
    },
    {
      "value": "SZ",
      "label": "Eswatini",
      "details": {
        "code": "SZ",
        "name": "Eswatini",
        "region": "africa"
      },
      "key": "SZ"
    },
    {
      "value": "ET",
      "label": "Ethiopia",
      "details": {
        "code": "ET",
        "name": "Ethiopia",
        "region": "africa"
      },
      "key": "ET"
    },
    {
      "value": "FK",
      "label": "Falkland Islands",
      "details": {
        "code": "FK",
        "name": "Falkland Islands",
        "region": "americas"
      },
      "key": "FK"
    },
    {
      "value": "FO",
      "label": "Faroe Islands",
      "details": {
        "code": "FO",
        "name": "Faroe Islands",
        "region": "europe"
      },
      "key": "FO"
    },
    {
      "value": "FJ",
      "label": "Fiji",
      "details": {
        "code": "FJ",
        "name": "Fiji",
        "region": "oceania"
      },
      "key": "FJ"
    },
    {
      "value": "FI",
      "label": "Finland",
      "details": {
        "code": "FI",
        "name": "Finland",
        "region": "europe"
      },
      "key": "FI"
    },
    {
      "value": "FR",
      "label": "France",
      "details": {
        "code": "FR",
        "name": "France",
        "region": "europe"
      },
      "key": "FR"
    },
    {
      "value": "GF",
      "label": "French Guiana",
      "details": {
        "code": "GF",
        "name": "French Guiana",
        "region": "americas"
      },
      "key": "GF"
    },
    {
      "value": "PF",
      "label": "French Polynesia",
      "details": {
        "code": "PF",
        "name": "French Polynesia",
        "region": "oceania"
      },
      "key": "PF"
    },
    {
      "value": "TF",
      "label": "French Southern Territories",
      "details": {
        "code": "TF",
        "name": "French Southern Territories",
        "region": "africa"
      },
      "key": "TF"
    },
    {
      "value": "GA",
      "label": "Gabon",
      "details": {
        "code": "GA",
        "name": "Gabon",
        "region": "africa"
      },
      "key": "GA"
    },
    {
      "value": "GM",
      "label": "Gambia",
      "details": {
        "code": "GM",
        "name": "Gambia",
        "region": "africa"
      },
      "key": "GM"
    },
    {
      "value": "GE",
      "label": "Georgia",
      "details": {
        "code": "GE",
        "name": "Georgia",
        "region": "asia"
      },
      "key": "GE"
    },
    {
      "value": "DE",
      "label": "Germany",
      "details": {
        "code": "DE",
        "name": "Germany",
        "region": "europe"
      },
      "key": "DE"
    },
    {
      "value": "GH",
      "label": "Ghana",
      "details": {
        "code": "GH",
        "name": "Ghana",
        "region": "africa"
      },
      "key": "GH"
    },
    {
      "value": "GI",
      "label": "Gibraltar",
      "details": {
        "code": "GI",
        "name": "Gibraltar",
        "region": "europe"
      },
      "key": "GI"
    },
    {
      "value": "GR",
      "label": "Greece",
      "details": {
        "code": "GR",
        "name": "Greece",
        "region": "europe"
      },
      "key": "GR"
    },
    {
      "value": "GL",
      "label": "Greenland",
      "details": {
        "code": "GL",
        "name": "Greenland",
        "region": "americas"
      },
      "key": "GL"
    },
    {
      "value": "GD",
      "label": "Grenada",
      "details": {
        "code": "GD",
        "name": "Grenada",
        "region": "americas"
      },
      "key": "GD"
    },
    {
      "value": "GP",
      "label": "Guadeloupe",
      "details": {
        "code": "GP",
        "name": "Guadeloupe",
        "region": "americas"
      },
      "key": "GP"
    },
    {
      "value": "GU",
      "label": "Guam",
      "details": {
        "code": "GU",
        "name": "Guam",
        "region": "oceania"
      },
      "key": "GU"
    },
    {
      "value": "GT",
      "label": "Guatemala",
      "details": {
        "code": "GT",
        "name": "Guatemala",
        "region": "americas"
      },
      "key": "GT"
    },
    {
      "value": "GG",
      "label": "Guernsey",
      "details": {
        "code": "GG",
        "name": "Guernsey",
        "region": "europe"
      },
      "key": "GG"
    },
    {
      "value": "GN",
      "label": "Guinea",
      "details": {
        "code": "GN",
        "name": "Guinea",
        "region": "africa"
      },
      "key": "GN"
    },
    {
      "value": "GW",
      "label": "Guinea-Bissau",
      "details": {
        "code": "GW",
        "name": "Guinea-Bissau",
        "region": "africa"
      },
      "key": "GW"
    },
    {
      "value": "GY",
      "label": "Guyana",
      "details": {
        "code": "GY",
        "name": "Guyana",
        "region": "americas"
      },
      "key": "GY"
    },
    {
      "value": "HT",
      "label": "Haiti",
      "details": {
        "code": "HT",
        "name": "Haiti",
        "region": "americas"
      },
      "key": "HT"
    },
    {
      "value": "HM",
      "label": "Heard Island and McDonald Islands",
      "details": {
        "code": "HM",
        "name": "Heard Island and McDonald Islands",
        "region": ""
      },
      "key": "HM"
    },
    {
      "value": "HN",
      "label": "Honduras",
      "details": {
        "code": "HN",
        "name": "Honduras",
        "region": "americas"
      },
      "key": "HN"
    },
    {
      "value": "HK",
      "label": "Hong Kong",
      "details": {
        "code": "HK",
        "name": "Hong Kong",
        "region": "asia"
      },
      "key": "HK"
    },
    {
      "value": "HU",
      "label": "Hungary",
      "details": {
        "code": "HU",
        "name": "Hungary",
        "region": "europe"
      },
      "key": "HU"
    },
    {
      "value": "IS",
      "label": "Iceland",
      "details": {
        "code": "IS",
        "name": "Iceland",
        "region": "europe"
      },
      "key": "IS"
    },
    {
      "value": "IN",
      "label": "India",
      "details": {
        "code": "IN",
        "name": "India",
        "region": "asia"
      },
      "key": "IN"
    },
    {
      "value": "ID",
      "label": "Indonesia",
      "details": {
        "code": "ID",
        "name": "Indonesia",
        "region": "asia"
      },
      "key": "ID"
    },
    {
      "value": "IR",
      "label": "Iran",
      "details": {
        "code": "IR",
        "name": "Iran",
        "region": "asia"
      },
      "key": "IR"
    },
    {
      "value": "IQ",
      "label": "Iraq",
      "details": {
        "code": "IQ",
        "name": "Iraq",
        "region": "asia"
      },
      "key": "IQ"
    },
    {
      "value": "IE",
      "label": "Ireland",
      "details": {
        "code": "IE",
        "name": "Ireland",
        "region": "europe"
      },
      "key": "IE"
    },
    {
      "value": "IM",
      "label": "Isle of Man",
      "details": {
        "code": "IM",
        "name": "Isle of Man",
        "region": "europe"
      },
      "key": "IM"
    },
    {
      "value": "IL",
      "label": "Israel",
      "details": {
        "code": "IL",
        "name": "Israel",
        "region": "asia"
      },
      "key": "IL"
    },
    {
      "value": "IT",
      "label": "Italy",
      "details": {
        "code": "IT",
        "name": "Italy",
        "region": "europe"
      },
      "key": "IT"
    },
    {
      "value": "CI",
      "label": "Ivory Coast",
      "details": {
        "code": "CI",
        "name": "Ivory Coast",
        "region": "africa"
      },
      "key": "CI"
    },
    {
      "value": "JM",
      "label": "Jamaica",
      "details": {
        "code": "JM",
        "name": "Jamaica",
        "region": "americas"
      },
      "key": "JM"
    },
    {
      "value": "JP",
      "label": "Japan",
      "details": {
        "code": "JP",
        "name": "Japan",
        "region": "asia"
      },
      "key": "JP"
    },
    {
      "value": "JE",
      "label": "Jersey",
      "details": {
        "code": "JE",
        "name": "Jersey",
        "region": "europe"
      },
      "key": "JE"
    },
    {
      "value": "JO",
      "label": "Jordan",
      "details": {
        "code": "JO",
        "name": "Jordan",
        "region": "asia"
      },
      "key": "JO"
    },
    {
      "value": "KZ",
      "label": "Kazakhstan",
      "details": {
        "code": "KZ",
        "name": "Kazakhstan",
        "region": "asia"
      },
      "key": "KZ"
    },
    {
      "value": "KE",
      "label": "Kenya",
      "details": {
        "code": "KE",
        "name": "Kenya",
        "region": "africa"
      },
      "key": "KE"
    },
    {
      "value": "KI",
      "label": "Kiribati",
      "details": {
        "code": "KI",
        "name": "Kiribati",
        "region": "oceania"
      },
      "key": "KI"
    },
    {
      "value": "XK",
      "label": "Kosovo",
      "details": {
        "code": "XK",
        "name": "Kosovo"
      },
      "key": "XK"
    },
    {
      "value": "KW",
      "label": "Kuwait",
      "details": {
        "code": "KW",
        "name": "Kuwait",
        "region": "asia"
      },
      "key": "KW"
    },
    {
      "value": "KG",
      "label": "Kyrgyzstan",
      "details": {
        "code": "KG",
        "name": "Kyrgyzstan",
        "region": "asia"
      },
      "key": "KG"
    },
    {
      "value": "LA",
      "label": "Laos",
      "details": {
        "code": "LA",
        "name": "Laos",
        "region": "asia"
      },
      "key": "LA"
    },
    {
      "value": "LV",
      "label": "Latvia",
      "details": {
        "code": "LV",
        "name": "Latvia",
        "region": "europe"
      },
      "key": "LV"
    },
    {
      "value": "LB",
      "label": "Lebanon",
      "details": {
        "code": "LB",
        "name": "Lebanon",
        "region": "asia"
      },
      "key": "LB"
    },
    {
      "value": "LS",
      "label": "Lesotho",
      "details": {
        "code": "LS",
        "name": "Lesotho",
        "region": "africa"
      },
      "key": "LS"
    },
    {
      "value": "LR",
      "label": "Liberia",
      "details": {
        "code": "LR",
        "name": "Liberia",
        "region": "africa"
      },
      "key": "LR"
    },
    {
      "value": "LY",
      "label": "Libya",
      "details": {
        "code": "LY",
        "name": "Libya",
        "region": "africa"
      },
      "key": "LY"
    },
    {
      "value": "LI",
      "label": "Liechtenstein",
      "details": {
        "code": "LI",
        "name": "Liechtenstein",
        "region": "europe"
      },
      "key": "LI"
    },
    {
      "value": "LT",
      "label": "Lithuania",
      "details": {
        "code": "LT",
        "name": "Lithuania",
        "region": "europe"
      },
      "key": "LT"
    },
    {
      "value": "LU",
      "label": "Luxembourg",
      "details": {
        "code": "LU",
        "name": "Luxembourg",
        "region": "europe"
      },
      "key": "LU"
    },
    {
      "value": "MO",
      "label": "Macao",
      "details": {
        "code": "MO",
        "name": "Macao",
        "region": "asia"
      },
      "key": "MO"
    },
    {
      "value": "MG",
      "label": "Madagascar",
      "details": {
        "code": "MG",
        "name": "Madagascar",
        "region": "africa"
      },
      "key": "MG"
    },
    {
      "value": "MW",
      "label": "Malawi",
      "details": {
        "code": "MW",
        "name": "Malawi",
        "region": "africa"
      },
      "key": "MW"
    },
    {
      "value": "MY",
      "label": "Malaysia",
      "details": {
        "code": "MY",
        "name": "Malaysia",
        "region": "asia"
      },
      "key": "MY"
    },
    {
      "value": "MV",
      "label": "Maldives",
      "details": {
        "code": "MV",
        "name": "Maldives",
        "region": "asia"
      },
      "key": "MV"
    },
    {
      "value": "ML",
      "label": "Mali",
      "details": {
        "code": "ML",
        "name": "Mali",
        "region": "africa"
      },
      "key": "ML"
    },
    {
      "value": "MT",
      "label": "Malta",
      "details": {
        "code": "MT",
        "name": "Malta",
        "region": "europe"
      },
      "key": "MT"
    },
    {
      "value": "MH",
      "label": "Marshall Islands",
      "details": {
        "code": "MH",
        "name": "Marshall Islands",
        "region": "oceania"
      },
      "key": "MH"
    },
    {
      "value": "MQ",
      "label": "Martinique",
      "details": {
        "code": "MQ",
        "name": "Martinique",
        "region": "americas"
      },
      "key": "MQ"
    },
    {
      "value": "MR",
      "label": "Mauritania",
      "details": {
        "code": "MR",
        "name": "Mauritania",
        "region": "africa"
      },
      "key": "MR"
    },
    {
      "value": "MU",
      "label": "Mauritius",
      "details": {
        "code": "MU",
        "name": "Mauritius",
        "region": "africa"
      },
      "key": "MU"
    },
    {
      "value": "YT",
      "label": "Mayotte",
      "details": {
        "code": "YT",
        "name": "Mayotte",
        "region": "africa"
      },
      "key": "YT"
    },
    {
      "value": "MX",
      "label": "MexiCo",
      "details": {
        "code": "MX",
        "name": "MexiCo",
        "region": "americas"
      },
      "key": "MX"
    },
    {
      "value": "FM",
      "label": "Micronesia",
      "details": {
        "code": "FM",
        "name": "Micronesia",
        "region": "oceania"
      },
      "key": "FM"
    },
    {
      "value": "MD",
      "label": "Moldova",
      "details": {
        "code": "MD",
        "name": "Moldova",
        "region": "europe"
      },
      "key": "MD"
    },
    {
      "value": "MC",
      "label": "Monaco",
      "details": {
        "code": "MC",
        "name": "Monaco",
        "region": "europe"
      },
      "key": "MC"
    },
    {
      "value": "MN",
      "label": "Mongolia",
      "details": {
        "code": "MN",
        "name": "Mongolia",
        "region": "asia"
      },
      "key": "MN"
    },
    {
      "value": "ME",
      "label": "Montenegro",
      "details": {
        "code": "ME",
        "name": "Montenegro",
        "region": "europe"
      },
      "key": "ME"
    },
    {
      "value": "MS",
      "label": "Montserrat",
      "details": {
        "code": "MS",
        "name": "Montserrat",
        "region": "americas"
      },
      "key": "MS"
    },
    {
      "value": "MA",
      "label": "Morocco",
      "details": {
        "code": "MA",
        "name": "Morocco",
        "region": "africa"
      },
      "key": "MA"
    },
    {
      "value": "MZ",
      "label": "Mozambique",
      "details": {
        "code": "MZ",
        "name": "Mozambique",
        "region": "africa"
      },
      "key": "MZ"
    },
    {
      "value": "MM",
      "label": "Myanmar",
      "details": {
        "code": "MM",
        "name": "Myanmar",
        "region": "asia"
      },
      "key": "MM"
    },
    {
      "value": "NA",
      "label": "Namibia",
      "details": {
        "code": "NA",
        "name": "Namibia",
        "region": "africa"
      },
      "key": "NA"
    },
    {
      "value": "NR",
      "label": "Nauru",
      "details": {
        "code": "NR",
        "name": "Nauru",
        "region": "oceania"
      },
      "key": "NR"
    },
    {
      "value": "NP",
      "label": "Nepal",
      "details": {
        "code": "NP",
        "name": "Nepal",
        "region": "asia"
      },
      "key": "NP"
    },
    {
      "value": "NL",
      "label": "Netherlands",
      "details": {
        "code": "NL",
        "name": "Netherlands",
        "region": "europe"
      },
      "key": "NL"
    },
    {
      "value": "NC",
      "label": "New Caledonia",
      "details": {
        "code": "NC",
        "name": "New Caledonia",
        "region": "oceania"
      },
      "key": "NC"
    },
    {
      "value": "NZ",
      "label": "New Zealand",
      "details": {
        "code": "NZ",
        "name": "New Zealand",
        "region": "oceania"
      },
      "key": "NZ"
    },
    {
      "value": "NI",
      "label": "Nicaragua",
      "details": {
        "code": "NI",
        "name": "Nicaragua",
        "region": "americas"
      },
      "key": "NI"
    },
    {
      "value": "NE",
      "label": "Niger",
      "details": {
        "code": "NE",
        "name": "Niger",
        "region": "africa"
      },
      "key": "NE"
    },
    {
      "value": "NG",
      "label": "Nigeria",
      "details": {
        "code": "NG",
        "name": "Nigeria",
        "region": "africa"
      },
      "key": "NG"
    },
    {
      "value": "NU",
      "label": "Niue",
      "details": {
        "code": "NU",
        "name": "Niue",
        "region": "oceania"
      },
      "key": "NU"
    },
    {
      "value": "NF",
      "label": "Norfolk Island",
      "details": {
        "code": "NF",
        "name": "Norfolk Island",
        "region": "oceania"
      },
      "key": "NF"
    },
    {
      "value": "KP",
      "label": "North Korea",
      "details": {
        "code": "KP",
        "name": "North Korea",
        "region": "asia"
      },
      "key": "KP"
    },
    {
      "value": "MK",
      "label": "North Macedonia",
      "details": {
        "code": "MK",
        "name": "North Macedonia",
        "region": "europe"
      },
      "key": "MK"
    },
    {
      "value": "MP",
      "label": "Northern Mariana Islands",
      "details": {
        "code": "MP",
        "name": "Northern Mariana Islands",
        "region": "oceania"
      },
      "key": "MP"
    },
    {
      "value": "NO",
      "label": "Norway",
      "details": {
        "code": "NO",
        "name": "Norway",
        "region": "europe"
      },
      "key": "NO"
    },
    {
      "value": "OM",
      "label": "Oman",
      "details": {
        "code": "OM",
        "name": "Oman",
        "region": "asia"
      },
      "key": "OM"
    },
    {
      "value": "PK",
      "label": "Pakistan",
      "details": {
        "code": "PK",
        "name": "Pakistan",
        "region": "asia"
      },
      "key": "PK"
    },
    {
      "value": "PW",
      "label": "Palau",
      "details": {
        "code": "PW",
        "name": "Palau",
        "region": "oceania"
      },
      "key": "PW"
    },
    {
      "value": "PS",
      "label": "Palestine",
      "details": {
        "code": "PS",
        "name": "Palestine",
        "region": "asia"
      },
      "key": "PS"
    },
    {
      "value": "PA",
      "label": "Panama",
      "details": {
        "code": "PA",
        "name": "Panama",
        "region": "americas"
      },
      "key": "PA"
    },
    {
      "value": "PG",
      "label": "Papua New Guinea",
      "details": {
        "code": "PG",
        "name": "Papua New Guinea",
        "region": "oceania"
      },
      "key": "PG"
    },
    {
      "value": "PY",
      "label": "Paraguay",
      "details": {
        "code": "PY",
        "name": "Paraguay",
        "region": "americas"
      },
      "key": "PY"
    },
    {
      "value": "PE",
      "label": "Peru",
      "details": {
        "code": "PE",
        "name": "Peru",
        "region": "americas"
      },
      "key": "PE"
    },
    {
      "value": "PH",
      "label": "Philippines",
      "details": {
        "code": "PH",
        "name": "Philippines",
        "region": "asia"
      },
      "key": "PH"
    },
    {
      "value": "PN",
      "label": "Pitcairn Islands",
      "details": {
        "code": "PN",
        "name": "Pitcairn Islands",
        "region": "oceania"
      },
      "key": "PN"
    },
    {
      "value": "PL",
      "label": "Poland",
      "details": {
        "code": "PL",
        "name": "Poland",
        "region": "europe"
      },
      "key": "PL"
    },
    {
      "value": "PT",
      "label": "Portugal",
      "details": {
        "code": "PT",
        "name": "Portugal",
        "region": "europe"
      },
      "key": "PT"
    },
    {
      "value": "PR",
      "label": "Puerto Rico",
      "details": {
        "code": "PR",
        "name": "Puerto Rico",
        "region": "americas"
      },
      "key": "PR"
    },
    {
      "value": "QA",
      "label": "Qatar",
      "details": {
        "code": "QA",
        "name": "Qatar",
        "region": "asia"
      },
      "key": "QA"
    },
    {
      "value": "RO",
      "label": "Romania",
      "details": {
        "code": "RO",
        "name": "Romania",
        "region": "europe"
      },
      "key": "RO"
    },
    {
      "value": "RU",
      "label": "Russia",
      "details": {
        "code": "RU",
        "name": "Russia",
        "region": "europe"
      },
      "key": "RU"
    },
    {
      "value": "RW",
      "label": "Rwanda",
      "details": {
        "code": "RW",
        "name": "Rwanda",
        "region": "africa"
      },
      "key": "RW"
    },
    {
      "value": "RE",
      "label": "Réunion",
      "details": {
        "code": "RE",
        "name": "Réunion",
        "region": "africa"
      },
      "key": "RE"
    },
    {
      "value": "BL",
      "label": "Saint Barthélemy",
      "details": {
        "code": "BL",
        "name": "Saint Barthélemy",
        "region": "americas"
      },
      "key": "BL"
    },
    {
      "value": "SH",
      "label": "Saint Helena",
      "details": {
        "code": "SH",
        "name": "Saint Helena",
        "region": "africa"
      },
      "key": "SH"
    },
    {
      "value": "LC",
      "label": "Saint Lucia",
      "details": {
        "code": "LC",
        "name": "Saint Lucia",
        "region": "americas"
      },
      "key": "LC"
    },
    {
      "value": "MF",
      "label": "Saint Martin",
      "details": {
        "code": "MF",
        "name": "Saint Martin",
        "region": "americas"
      },
      "key": "MF"
    },
    {
      "value": "PM",
      "label": "Saint Pierre and Miquelon",
      "details": {
        "code": "PM",
        "name": "Saint Pierre and Miquelon",
        "region": "americas"
      },
      "key": "PM"
    },
    {
      "value": "WS",
      "label": "Samoa",
      "details": {
        "code": "WS",
        "name": "Samoa",
        "region": "oceania"
      },
      "key": "WS"
    },
    {
      "value": "SM",
      "label": "San Marino",
      "details": {
        "code": "SM",
        "name": "San Marino",
        "region": "europe"
      },
      "key": "SM"
    },
    {
      "value": "SA",
      "label": "Saudi Arabia",
      "details": {
        "code": "SA",
        "name": "Saudi Arabia",
        "region": "asia"
      },
      "key": "SA"
    },
    {
      "value": "SN",
      "label": "Senegal",
      "details": {
        "code": "SN",
        "name": "Senegal",
        "region": "africa"
      },
      "key": "SN"
    },
    {
      "value": "RS",
      "label": "Serbia",
      "details": {
        "code": "RS",
        "name": "Serbia",
        "region": "europe"
      },
      "key": "RS"
    },
    {
      "value": "SC",
      "label": "SeyChelles",
      "details": {
        "code": "SC",
        "name": "SeyChelles",
        "region": "africa"
      },
      "key": "SC"
    },
    {
      "value": "SL",
      "label": "Sierra Leone",
      "details": {
        "code": "SL",
        "name": "Sierra Leone",
        "region": "africa"
      },
      "key": "SL"
    },
    {
      "value": "SG",
      "label": "Singapore",
      "details": {
        "code": "SG",
        "name": "Singapore",
        "region": "asia"
      },
      "key": "SG"
    },
    {
      "value": "XM",
      "label": "Sint Maarten",
      "details": {
        "code": "XM",
        "name": "Sint Maarten"
      },
      "key": "XM"
    },
    {
      "value": "SX",
      "label": "St. Maarten",
      "details": {
        "code": "SX",
        "name": "St. Maarten",
        "region": "americas"
      },
      "key": "SX"
    },
    {
      "value": "SK",
      "label": "Slovakia",
      "details": {
        "code": "SK",
        "name": "Slovakia",
        "region": "europe"
      },
      "key": "SK"
    },
    {
      "value": "SI",
      "label": "Slovenia",
      "details": {
        "code": "SI",
        "name": "Slovenia",
        "region": "europe"
      },
      "key": "SI"
    },
    {
      "value": "SB",
      "label": "Solomon Islands",
      "details": {
        "code": "SB",
        "name": "Solomon Islands",
        "region": "oceania"
      },
      "key": "SB"
    },
    {
      "value": "SO",
      "label": "Somalia",
      "details": {
        "code": "SO",
        "name": "Somalia",
        "region": "africa"
      },
      "key": "SO"
    },
    {
      "value": "ZA",
      "label": "South Africa",
      "details": {
        "code": "ZA",
        "name": "South Africa",
        "region": "africa"
      },
      "key": "ZA"
    },
    {
      "value": "GS",
      "label": "South Georgia and South Sandwich Islands",
      "details": {
        "code": "GS",
        "name": "South Georgia and South Sandwich Islands",
        "region": "americas"
      },
      "key": "GS"
    },
    {
      "value": "KR",
      "label": "South Korea",
      "details": {
        "code": "KR",
        "name": "South Korea",
        "region": "asia"
      },
      "key": "KR"
    },
    {
      "value": "SS",
      "label": "South Sudan",
      "details": {
        "code": "SS",
        "name": "South Sudan",
        "region": "africa"
      },
      "key": "SS"
    },
    {
      "value": "ES",
      "label": "Spain",
      "details": {
        "code": "ES",
        "name": "Spain",
        "region": "europe"
      },
      "key": "ES"
    },
    {
      "value": "LK",
      "label": "Sri Lanka",
      "details": {
        "code": "LK",
        "name": "Sri Lanka",
        "region": "asia"
      },
      "key": "LK"
    },
    {
      "value": "KN",
      "label": "St Kitts and Nevis",
      "details": {
        "code": "KN",
        "name": "St Kitts and Nevis",
        "region": "americas"
      },
      "key": "KN"
    },
    {
      "value": "VC",
      "label": "St VinCent and Grenadines",
      "details": {
        "code": "VC",
        "name": "St VinCent and Grenadines",
        "region": "americas"
      },
      "key": "VC"
    },
    {
      "value": "SD",
      "label": "Sudan",
      "details": {
        "code": "SD",
        "name": "Sudan",
        "region": "africa"
      },
      "key": "SD"
    },
    {
      "value": "SR",
      "label": "Suriname",
      "details": {
        "code": "SR",
        "name": "Suriname",
        "region": "americas"
      },
      "key": "SR"
    },
    {
      "value": "SJ",
      "label": "Svalbard and Jan Mayen",
      "details": {
        "code": "SJ",
        "name": "Svalbard and Jan Mayen",
        "region": "europe"
      },
      "key": "SJ"
    },
    {
      "value": "SE",
      "label": "Sweden",
      "details": {
        "code": "SE",
        "name": "Sweden",
        "region": "europe"
      },
      "key": "SE"
    },
    {
      "value": "CH",
      "label": "Switzerland",
      "details": {
        "code": "CH",
        "name": "Switzerland",
        "region": "europe"
      },
      "key": "CH"
    },
    {
      "value": "SY",
      "label": "Syria",
      "details": {
        "code": "SY",
        "name": "Syria",
        "region": "asia"
      },
      "key": "SY"
    },
    {
      "value": "ST",
      "label": "São Tomé and Príncipe",
      "details": {
        "code": "ST",
        "name": "São Tomé and Príncipe",
        "region": "africa"
      },
      "key": "ST"
    },
    {
      "value": "TW",
      "label": "Taiwan",
      "details": {
        "code": "TW",
        "name": "Taiwan",
        "region": "asia"
      },
      "key": "TW"
    },
    {
      "value": "TJ",
      "label": "Tajikistan",
      "details": {
        "code": "TJ",
        "name": "Tajikistan",
        "region": "asia"
      },
      "key": "TJ"
    },
    {
      "value": "TZ",
      "label": "Tanzania",
      "details": {
        "code": "TZ",
        "name": "Tanzania",
        "region": "africa"
      },
      "key": "TZ"
    },
    {
      "value": "TH",
      "label": "Thailand",
      "details": {
        "code": "TH",
        "name": "Thailand",
        "region": "asia"
      },
      "key": "TH"
    },
    {
      "value": "TL",
      "label": "Timor-Leste",
      "details": {
        "code": "TL",
        "name": "Timor-Leste",
        "region": "asia"
      },
      "key": "TL"
    },
    {
      "value": "TG",
      "label": "Togo",
      "details": {
        "code": "TG",
        "name": "Togo",
        "region": "africa"
      },
      "key": "TG"
    },
    {
      "value": "TK",
      "label": "Tokelau",
      "details": {
        "code": "TK",
        "name": "Tokelau",
        "region": "oceania"
      },
      "key": "TK"
    },
    {
      "value": "TO",
      "label": "Tonga",
      "details": {
        "code": "TO",
        "name": "Tonga",
        "region": "oceania"
      },
      "key": "TO"
    },
    {
      "value": "TT",
      "label": "Trinidad and Tobago",
      "details": {
        "code": "TT",
        "name": "Trinidad and Tobago",
        "region": "americas"
      },
      "key": "TT"
    },
    {
      "value": "TN",
      "label": "Tunisia",
      "details": {
        "code": "TN",
        "name": "Tunisia",
        "region": "africa"
      },
      "key": "TN"
    },
    {
      "value": "TR",
      "label": "Turkey",
      "details": {
        "code": "TR",
        "name": "Turkey",
        "region": "asia"
      },
      "key": "TR"
    },
    {
      "value": "TM",
      "label": "Turkmenistan",
      "details": {
        "code": "TM",
        "name": "Turkmenistan",
        "region": "asia"
      },
      "key": "TM"
    },
    {
      "value": "TC",
      "label": "Turks and Caicos Islands",
      "details": {
        "code": "TC",
        "name": "Turks and Caicos Islands",
        "region": "americas"
      },
      "key": "TC"
    },
    {
      "value": "TV",
      "label": "Tuvalu",
      "details": {
        "code": "TV",
        "name": "Tuvalu",
        "region": "oceania"
      },
      "key": "TV"
    },
    {
      "value": "UM",
      "label": "U.S. Minor Outlying Islands",
      "details": {
        "code": "UM",
        "name": "U.S. Minor Outlying Islands",
        "region": "americas"
      },
      "key": "UM"
    },
    {
      "value": "VI",
      "label": "U.S. Virgin Islands",
      "details": {
        "code": "VI",
        "name": "U.S. Virgin Islands",
        "region": "americas"
      },
      "key": "VI"
    },
    {
      "value": "UG",
      "label": "Uganda",
      "details": {
        "code": "UG",
        "name": "Uganda",
        "region": "africa"
      },
      "key": "UG"
    },
    {
      "value": "UA",
      "label": "Ukraine",
      "details": {
        "code": "UA",
        "name": "Ukraine",
        "region": "europe"
      },
      "key": "UA"
    },
    {
      "value": "AE",
      "label": "United Arab Emirates",
      "details": {
        "code": "AE",
        "name": "United Arab Emirates",
        "region": "asia"
      },
      "key": "AE"
    },
    {
      "value": "GB",
      "label": "United Kingdom",
      "details": {
        "code": "GB",
        "name": "United Kingdom",
        "region": "europe"
      },
      "key": "GB"
    },
    {
      "value": "US",
      "label": "United States",
      "details": {
        "code": "US",
        "name": "United States",
        "region": "americas"
      },
      "key": "US"
    },
    {
      "value": "UY",
      "label": "Uruguay",
      "details": {
        "code": "UY",
        "name": "Uruguay",
        "region": "americas"
      },
      "key": "UY"
    },
    {
      "value": "UZ",
      "label": "Uzbekistan",
      "details": {
        "code": "UZ",
        "name": "Uzbekistan",
        "region": "asia"
      },
      "key": "UZ"
    },
    {
      "value": "VU",
      "label": "Vanuatu",
      "details": {
        "code": "VU",
        "name": "Vanuatu",
        "region": "oceania"
      },
      "key": "VU"
    },
    {
      "value": "VA",
      "label": "Vatican City",
      "details": {
        "code": "VA",
        "name": "Vatican City",
        "region": "europe"
      },
      "key": "VA"
    },
    {
      "value": "VE",
      "label": "Venezuela",
      "details": {
        "code": "VE",
        "name": "Venezuela",
        "region": "americas"
      },
      "key": "VE"
    },
    {
      "value": "VN",
      "label": "Vietnam",
      "details": {
        "code": "VN",
        "name": "Vietnam",
        "region": "asia"
      },
      "key": "VN"
    },
    {
      "value": "WF",
      "label": "Wallis and Futuna",
      "details": {
        "code": "WF",
        "name": "Wallis and Futuna",
        "region": "oceania"
      },
      "key": "WF"
    },
    {
      "value": "EH",
      "label": "Western Sahara",
      "details": {
        "code": "EH",
        "name": "Western Sahara",
        "region": "africa"
      },
      "key": "EH"
    },
    {
      "value": "YE",
      "label": "Yemen",
      "details": {
        "code": "YE",
        "name": "Yemen",
        "region": "asia"
      },
      "key": "YE"
    },
    {
      "value": "ZM",
      "label": "Zambia",
      "details": {
        "code": "ZM",
        "name": "Zambia",
        "region": "africa"
      },
      "key": "ZM"
    },
    {
      "value": "ZW",
      "label": "Zimbabwe",
      "details": {
        "code": "ZW",
        "name": "Zimbabwe",
        "region": "africa"
      },
      "key": "ZW"
    },
    {
      "value": "AX",
      "label": "Åland",
      "details": {
        "code": "AX",
        "name": "Åland",
        "region": "europe"
      },
      "key": "AX"
    }
  ]