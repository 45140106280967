import { Layout, Row,Col, Button } from 'antd';
import Header from '../components/header.js'
import Footer from '../components/footer.js';
import Typography from 'antd/es/typography/Typography.js';
import { useNavigate } from "react-router-dom";
const { Content} = Layout

const contentStyle = {
    minHeight: "100%",
    lineHeight: '60px',
    color: '#fff',
    height: "calc(100vh - 24vh)",
    textAlign: 'center'
  };

export default function Root() {
    const navigate = useNavigate();
    // useEffect(() => {
    //     fetch('http://localhost:3000/parcels.json').then(res => {console.log(res.json())})

    // })
    return (        
    <Layout>
        <Header />
        <Layout style={contentStyle}>
            <Content>
            <Row>
                <Col span={24} style={{marginTop:30}}>
                    <Typography>Welcome to SampleShip</Typography>
                    <Typography>Please click the start button to beign the process of registering your sample(s).</Typography>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                   <Button onClick={() => {navigate('/register-parcels');}}>Start</Button>
                </Col>
            </Row>
            </Content>
        </Layout>
        <Footer />
    </Layout>
    );
  }