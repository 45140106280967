import { Card } from 'antd';
import Typography from 'antd/es/typography/Typography.js';
import { CalendarOutlined, ClockCircleOutlined, EnvironmentOutlined} from '@ant-design/icons';
import dayjs from 'dayjs'


export default function DateTimePortDetails(props) {
  if(props.collectionRequired){
    if (props.parcelType === 'vessel'){
      return (        
        <Card title={"Date, Time and Port"} hoverable>
          <Typography><CalendarOutlined style={{marginRight:5}} />{dayjs(props.collectionDate).format("dddd DD MMMM YYYY")}</Typography>
          <Typography><ClockCircleOutlined style={{marginRight:5}} />{dayjs(props.collectionEarly).format("HH:mm")} - {dayjs(props.collectionLate).format("HH:mm")}</Typography>
          <Typography><EnvironmentOutlined  style={{marginRight:5}} />{props.bunkerPort}</Typography>
        </Card>
    );
    } else {
      return (        
        <Card title={"Date and Time"} hoverable>
          <Typography><CalendarOutlined style={{marginRight:5}} />{dayjs(props.collectionDate).format("dddd DD MMMM YYYY")}</Typography>
          <Typography><ClockCircleOutlined style={{marginRight:5}} />{dayjs(props.collectionEarly).format("HH:mm")} - {dayjs(props.collectionLate).format("HH:mm")}</Typography>
        </Card>
    );
    }
   
  } else {
    return null;
  }
    
  }