import { Card } from 'antd';
import Typography from 'antd/es/typography/Typography.js';
import { useEffect } from 'react';

function returnParcelNumber(parcel, parcelNumbersArray){
  const lastElement = parcelNumbersArray[parcelNumbersArray.length - 1]
  if (lastElement === parcel){
    if (parcel === undefined){
      return ""
    } else {
      return `${parcel}`
    }
  } else {
    if (parcel === undefined){
      return ""
    } else {
      return `${parcel} , `
    } 
  }
}

function RegisteredVessel(props){
  return ( 
    <Card title={props.data.vessel.name} hoverable>  
          <Typography>IMO: {props.data.vessel.imo}</Typography>
          <Typography>Client: {props.data.client.name}</Typography>
          {(props.numbers === undefined || props.numbers.length === 0 ? null : <Typography>Parcel Numbers: {props.numbers.map(parcel => {return returnParcelNumber(parcel,props.numbers)})}</Typography> )}
      </Card>
  );
}

function GenericVessel(props){
  return ( 
    <Card title={props.vessel} hoverable>  
          <Typography>IMO: {props.imo} </Typography>
          <Typography>Client: {props.data.client.name}</Typography>
          <Typography>Parcel Numbers: {props.numbers.map(parcel => {return returnParcelNumber(parcel,props.numbers)})}</Typography>
          
      </Card>
  );
}

export default function VesselDetails(props) {
  useEffect(() => {
    
  },[])
  if (props.show && props.registerPackage){
    return (<RegisteredVessel numbers={[]} data={props.data} />)
  } else {
    if (props.show && !props.data.parcel.generic){
      return <RegisteredVessel numbers={props.parcelNumbers} data={props.data} />
    } else if (props.show && props.vesselValidated) {
      return <RegisteredVessel numbers={props.parcelNumbers} data={props.data} />
    } else if (props.show && props.data.parcel.generic) {
      return <GenericVessel numbers={props.parcelNumbers} vessel={props.vessel} imo={props.imo} data={props.data} />
    } else {
      return null
    }
  }
  }