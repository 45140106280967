import { Card } from 'antd';
import Typography from 'antd/es/typography/Typography.js';
import {UserOutlined,PhoneOutlined, MailOutlined,SolutionOutlined} from '@ant-design/icons';


export default function ContactDetailsCard(props) {
    if (props.companyName){
        return (        
            <Card title={"Contact Details"} hoverable>
              <Typography><SolutionOutlined style={{marginRight:5}} />{props.companyName}</Typography>
              <Typography><UserOutlined style={{marginRight:5}} />{props.contactPerson}</Typography>
              <Typography><PhoneOutlined style={{marginRight:5}} />{props.contactPhone}</Typography>
              <Typography><MailOutlined style={{marginRight:5}} />{props.contactEmail}</Typography>
            </Card>
        );
    } else {
        return null;
    }
   
    
  }