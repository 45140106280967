import { Card } from 'antd';
import Typography from 'antd/es/typography/Typography.js';
import { useEffect } from 'react';

function returnParcelNumber(parcel, parcelNumbersArray){
  const lastElement = parcelNumbersArray[parcelNumbersArray.length - 1]
  if (lastElement === parcel){
    if (parcel === undefined){
      return ""
    } else {
      return `${parcel}`
    }
  } else {
    if (parcel === undefined){
      return ""
    } else {
      return `${parcel} , `
    } 
  }
}
function GenericDealer(props){
  return ( 
    <Card title={props.dealer} hoverable>  
          <Typography>Dealer ID: {props.dealerId} </Typography>
          <Typography>Client: {props.data.client.name}</Typography>
          <Typography>Parcel Numbers: {props.numbers.map(parcel => {return returnParcelNumber(parcel,props.numbers)})}</Typography>
      </Card>
  );
}

export default function DealerDetails(props) {
  useEffect(() => {
    
  },[])
  if (props.show) {
    return <GenericDealer numbers={props.parcelNumbers} dealer={props.dealer} dealerId={props.dealerId} data={props.data} />
  } else {
    return null
  }
   
  }