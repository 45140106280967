import { Form, Input } from 'antd';
import Typography from 'antd/es/typography/Typography.js';
import { useEffect } from 'react';

function GenericDealerForm(props){
  return ( 
    <>
        <Typography style={{marginTop:75}}>Please enter your Dealer Name and ID (if applicable) for the Sample(s) you are registering.</Typography>
        <div style={{maxWidth: 300, marginTop:10}}>
            <Form.Item name='vessel_name' rules={[ { required: true, max: 50, message: 'Dealer Name is required' }, ]} label='Dealer Name'><Input/></Form.Item>
            <Form.Item name='vessel_imo'  label='Dealer ID'><Input/></Form.Item>
        </div>
    </>
  );
}

function GenericVesselForm(props){
    return ( 
        <>
        <Typography style={{marginTop:75}}>Please enter the Vessel Name and IMO for the Sample(s) you are registering.</Typography>
        <div style={{maxWidth: 300, marginTop:10}}>
            <Form.Item name='vessel_name' rules={[ { required: true, max: 50, message: 'Vessel Name is required' }, ]} label='Vessel Name'><Input/></Form.Item>
            <Form.Item name='vessel_imo' rules={[ { required: true, max: 7, min:7, message: 'Vessel IMO is required' }, ]}  label='Vessel IMO'><Input/></Form.Item>
        </div>
        </>
    );
}

export default function GenericFormInput(props) {
  useEffect(() => {

  },[])
  if (props.parcelType === 'vessel'){
    return <GenericVesselForm data={props.data} />
  } else {
    return <GenericDealerForm data={props.data} />
  } 
   
  }