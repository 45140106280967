import { Card } from 'antd';
import Typography from 'antd/es/typography/Typography.js';
import countryList from 'country-list';
import getUnicodeFlagIcon from 'country-flag-icons/unicode'

export default function CollectionDetailsCard(props) {
    if (props.addressLineOne){
        return (   
           
            <Card title={"Collection Address"} hoverable>
                <Typography>{props.addressLineOne}</Typography>
                <Typography>{props.city}</Typography>
                {(props.postCodeReq ? null : <Typography>{props.postcode}</Typography>)}
                {(props.country ?  <Typography>{getUnicodeFlagIcon(props.country)} {countryList.getName(props.country)}</Typography> : null )}
            </Card>
        );
    } else {
        return null;
    }
   
  }